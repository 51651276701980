<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
          <b-col md="12">
            <b-col md="12">
              <b-form-group
                label="Home Page"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                  
LMAentre HomePage
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          

            <b-col cols="12">
              <b-form-group label="Title" label-for="blog-content" class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input v-model="addCourseForm.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input v-model="addCourseForm.description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Keywords"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-tags v-model="addCourseForm.keywords" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <seo-categoy-in-city-list ref="table"/>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
  BFormTags,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import SeoCategoyInCityList from "./SeoCategoyInCityList.vue";

import Vue from "vue";

export default {
  setup() {
    // const store = useStore();
    const table=ref()

    const coursesList = ref([]);
    const citiesList = ref([]);

    store.dispatch("cities/getAllcitites").then((response) => {
      console.log("response", response.data.data);
      citiesList.value = response.data.data;
    });

    store.dispatch("categories/AllCategory").then((response) => {
      console.log("response", response.data.data);
      coursesList.value = response.data.data;
    });
    const addCourseForm = reactive({
      category: "",
      keywords: "",
      city:'',
      title: "",
      description: '',
    });
  
    const addCourseFormvalidate = ref();

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("category_id", addCourseForm.category);
          formData.append("keywords", addCourseForm.keywords);
          formData.append("title", addCourseForm.title);
          formData.append("description", addCourseForm.description);
          formData.append("city_id", addCourseForm.city);
         
          store
            .dispatch("seo/addCategryInCity", formData)
            .then((response) => {
                  table.value.refetchData()
              Vue.swal({
                title: "Category In City Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
    };
 
    return {
     
      addCourseForm,
      table,
      addCourseFormvalidate,
      coursesList,
      citiesList,

      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BTable,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    SeoCategoyInCityList,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
